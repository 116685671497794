export enum RolePrivilege {
    Home = 'Home',
    Home_Alarms_List = 'Home_Alarms_List',
    Home_Alarms_Resources='Home_Alarms_Resources',
    Home_Alarms_Details = 'Home_Alarms_Details',
    Home_Alarms_Feedback = 'Home_Alarms_Feedback',
    Home_Alarms_SentStatistics = 'Home_Alarms_SentStatistics',
    Home_Alarms_PrintProtocol = 'Home_Alarms_PrintProtocol',
    Home_Alarms_Parameters_read = 'Home_Alarms_Parameters_read',
    Home_Alarms_Close = 'Home_Alarms_Close',
    Home_Alarms_Delete = 'Home_Alarms_Delete',
    Home_Alarms_MarkImportant = 'Home_Alarms_MarkImportant',
    Home_Emergency_List = 'Home_Emergency_List',
    Home_Emergency_List_Delete = 'Home_Emergency_List_Delete',
    Home_Emergency_Details = 'Home_Emergency_Details',
    Home_Emergency_Details_Alarms = 'Home_Emergency_Details_Alarms',
    Home_Emergency_Details_Alarms_Details = 'Home_Emergency_Details_Alarms_Details',
    Home_Emergency_Details_LocationMap = 'Home_Emergency_Details_LocationMap',
    Home_Emergency_Details_Report_Read = 'Home_Emergency_Details_Report_Read',
    Home_Emergency_Details_Report_Write = 'Home_Emergency_Details_Report_Write',
    Home_Emergency_Details_Report_Close = 'Home_Emergency_Details_Report_Close',
    Home_Emergency_Details_Export_Protocol = 'Home_Emergency_Details_Export_Protocol',
    Home_Emergency_Details_Export_Diary = 'Home_Emergency_Details_Export_Diary',
    Home_Emergency_Details_Export_LocationMap = 'Home_Emergency_Details_Export_LocationMap',
    Home_Dashboard_Inputs_read = 'Home_Dashboard_Inputs_read',
    Home_Dashboard_Inputs_link = 'Home_Dashboard_Inputs_link',
    Home_Dashboard_SMS_read = 'Home_Dashboard_SMS_read',
    Home_Dashboard_SMS_link = 'Home_Dashboard_SMS_link',
    Home_Dashboard_Maps_read = 'Home_Dashboard_Maps_read',
    Home_Dashboard_Maps_link = 'Home_Dashboard_Maps_link',
    Home_Dashboard_Alarms = 'Home_Dashboard_Alarms',
    Home_Dashboard_Calendar_read = 'Home_Dashboard_Calendar_read',
    Home_Dashboard_Calendar_link = 'Home_Dashboard_Calendar_link',
    Addressbook = 'Addressbook',
    Addressbook_Persons = 'Addressbook_Persons',
    Addressbook_Persons_Create = 'Addressbook_Persons_Create',
    Addressbook_Persons_Edit = 'Addressbook_Persons_Edit',
    Addressbook_Persons_Delete = 'Addressbook_Persons_Delete',
    Addressbook_Export = 'Addressbook_Export',
    Addressbook_AM4 = 'Addressbook_AM4',
    Addressbook_AM4_Create = 'Addressbook_AM4_Create',
    Addressbook_AM4_Edit = 'Addressbook_AM4_Edit',
    Addressbook_AM4_Delete = 'Addressbook_AM4_Delete',
    Addressbook_aMobile = 'Addressbook_aMobile',
    Addressbook_aMobile_Create = 'Addressbook_aMobile_Create',
    Addressbook_aMobile_Edit = 'Addressbook_aMobile_Edit',
    Addressbook_aMobile_Delete = 'Addressbook_aMobile_Delete',
    Addressbook_Provisioning = 'Addressbook_Provisioning',
    Addressbook_Geofences = 'Addressbook_Geofences',
    Addressbook_Geofences_Create = 'Addressbook_Geofences_Create',
    Addressbook_Geofences_Edit = 'Addressbook_Geofences_Edit',
    Addressbook_Geofences_Delete = 'Addressbook_Geofences_Delete',
    Addressbook_Provisioning_Create = 'Addressbook_Provisioning_Create',
    Addressbook_Provisioning_Edit = 'Addressbook_Provisioning_Edit',
    Addressbook_Provisioning_Delete = 'Addressbook_Provisioning_Delete',
    Addressbook_Provisioning_Send = 'Addressbook_Provisioning_Send',
    Addressbook_Provisioning_Assign = 'Addressbook_Provisioning_Assign',
    Addressbook_Location = 'Addressbook_Location',
    Addressbook_Location_Exact = 'Addressbook_Location_Exact',
    Addressbook_Overview = 'Addressbook_Overview',
    Addressbook_Functions_Groups_Edit = 'Addressbook_Functions_Groups_Edit',
    Addressbook_Persons_Share = 'Addressbook_Persons_Share',
    Addressbook_OnlineService = 'Addressbook_OnlineService',
    Addressbook_Availability_Edit = 'Addressbook_Availability_Edit',
    Addressbook_AlarmGroup = 'Addressbook_AlarmGroup',
    Addressbook_AlarmGroup_Create = 'Addressbook_AlarmGroup_Create',
    Addressbook_AlarmGroup_Edit = 'Addressbook_AlarmGroup_Edit',
    Addressbook_AlarmGroup_Delete = 'Addressbook_AlarmGroup_Delete',
    Addressbook_IOPrint_Read = 'Addressbook_IOPrint_Read',
    Station = 'Station',
    Station_Shiftbook = 'Station_Shiftbook',
    Station_Shiftbook_Export = 'Station_Shiftbook_Export',
    Station_Shiftbook_Categories_Create = 'Station_Shiftbook_Categories_Create',
    Station_Shiftbook_Categories_Edit = 'Station_Shiftbook_Categories_Edit',
    Station_Shiftbook_Categories_Delete = 'Station_Shiftbook_Categories_Delete',
    Station_Shiftbook_Fields_Create = 'Station_Shiftbook_Fields_Create',
    Station_Shiftbook_Fields_Edit = 'Station_Shiftbook_Fields_Edit',
    Station_Shiftbook_Fields_Delete = 'Station_Shiftbook_Fields_Delete',
    Station_Shiftbook_Reports_Create = 'Station_Shiftbook_Reports_Create',
    Station_Shiftbook_Reports_Edit = 'Station_Shiftbook_Reports_Edit',
    Station_Shiftbook_Reports_Delete = 'Station_Shiftbook_Reports_Delete',
    Station_Vehicles = 'Station_Vehicles',
    Station_Vehicles_ChangeStatus = 'Station_Vehicles_ChangeStatus',
    Station_Vehicles_ChangePosition = 'Station_Vehicles_ChangePosition',
    Station_Vehicles_ReadDetails = 'Station_Vehicles_ReadDetails',
    Station_Vehicles_Edit = 'Station_Vehicles_Edit',
    Station_Vehicles_Swap = 'Station_Vehicles_Swap',
    Station_Vehicles_Location = 'Station_Vehicles_Location',
    Station_Vehicles_TTS = 'Station_Vehicles_TTS',
    Station_Vehicles_TTS_Edit = 'Station_Vehicles_TTS_Edit',
    Station_Vehicles_Faults = 'Station_Vehicles_Faults',
    Station_Vehicles_Faults_Create = 'Station_Vehicles_Faults_Create',
    Station_Vehicles_Faults_Edit = 'Station_Vehicles_Faults_Edit',
    Station_Vehicles_Faults_Delete = 'Station_Vehicles_Faults_Delete',
    Station_Vehicles_Faults_Export = 'Station_Vehicles_Faults_Export',
    Station_Sirens = 'Station_Sirens',
    Station_Sirens_ChangeStatus = 'Station_Sirens_ChangeStatus',
    Station_Sirens_ReadDetails = 'Station_Sirens_ReadDetails',
    Station_Sirens_Edit = 'Station_Sirens_Edit',
    Station_Sirens_Faults = 'Station_Sirens_Faults',
    Station_Sirens_Faults_Create = 'Station_Sirens_Faults_Create',
    Station_Sirens_Faults_Edit = 'Station_Sirens_Faults_Edit',
    Station_Sirens_Faults_Delete = 'Station_Sirens_Faults_Delete',
    Station_Sirens_Faults_Export = 'Station_Sirens_Faults_Export',
    Station_Status = 'Station_Status',
    Station_Sirens_Status = 'Station_Sirens_Status',
    Station_Tableau = 'Station_Tableau',
    Station_Tableau_EditGrid = 'Station_Tableau_EditGrid',
    Station_Tableau_AccessSingleGrid = 'Station_Tableau_AccessSingleGrid',
    Station_Parking = 'Station_Parking',
    Station_Parking_Relais_Read = 'Station_Parking_Relais_Read',
    Station_Parking_Relais_Edit = 'Station_Parking_Relais_Edit',
    Station_History = 'Station_History',
    Station_Environment = 'Station_Environment',
    Station_Timeplan_Edit = 'Station_Timeplan_Edit',
    Station_Relais = 'Station_Relais',
    Station_Relais_Trigger = 'Station_Relais_Trigger',
    Units = 'Units',
    Units_Plugindetails = 'Units_Plugindetails',
    Units_Create = 'Units_Create',
    Units_Edit = 'Units_Edit',
    Units_Edit_Custom = 'Units_Edit_Custom',
    Units_Settings_Feedback = 'Units_Settings_Feedback',
    Units_Settings_Disable = 'Units_Settings_Disable',
    Units_Delete = 'Units_Delete',
    Units_Settings = 'Units_Settings',
    Units_Custom_Feedback = 'Units_Custom_Feedback',
    Units_Custom_Feedback_Create = 'Units_Custom_Feedback_Create',
    Units_Custom_Feedback_Edit = 'Units_Custom_Feedback_Edit',
    Units_Custom_Feedback_Delete = 'Units_Custom_Feedback_Delete',
    Unit_Templates = 'Unit_Templates',
    Unit_Templates_Create = 'Unit_Templates_Create',
    Unit_Templates_Delete = 'Unit_Templates_Delete',
    Units_Tracing = 'Units_Tracing',
    Alarm = 'Alarm',
    Alarm_Alerting = 'Alarm_Alerting',
    Alarm_Queues_Diary_Read = 'Alarm_Queues_Diary_Read',
    Alarm_Queues_Diary_Write = 'Alarm_Queues_Diary_Write',
    Alarm_Queues_Tasks_Read = 'Alarm_Queues_Tasks_Read',
    Alarm_Queues_Tasks_Write = 'Alarm_Queues_Tasks_Write',
    Alarm_Queues_Tasks_Read_All = 'Alarm_Queues_Tasks_Read_All',
    Alarm_Fake_Alarm = 'Alarm_Fake_Alarm',
    Alarm_Alerting_Templates_Create = 'Alarm_Alerting_Templates_Create',
    Alarm_Alerting_Templates_Edit = 'Alarm_Alerting_Templates_Edit',
    Alarm_Alerting_Templates_Delete = 'Alarm_Alerting_Templates_Delete',
    Alarm_Alerting_TemplateTable_Create = 'Alarm_Alerting_TemplateTable_Create',
    Alarm_Alerting_TemplateTable_Edit = 'Alarm_Alerting_TemplateTable_Edit',
    Alarm_Alerting_TemplateTable_Delete = 'Alarm_Alerting_TemplateTable_Delete',
    Alarm_Alerting_FromTemplateOnly = 'Alarm_Alerting_FromTemplateOnly',
    Alarm_Messages = 'Alarm_Messages',
    Alarm_Messages_Send = 'Alarm_Messages_Send',
    Alarm_Messages_Planned = 'Alarm_Messages_Planned',
    Alarm_Alerting_Templates = 'Alarm_Alerting_Templates',
    Alarm_Alerting_TemplateTable = 'Alarm_Alerting_TemplateTable',
    Alarm_Messages_Delete = 'Alarm_Messages_Delete',
    Alarm_Quickedit = 'Alarm_Quickedit',
    Objects = 'Objects',
    Objects_Database = 'Objects_Database',
    Objects_Database_Create = 'Objects_Database_Create',
    Objects_Database_Edit = 'Objects_Database_Edit',
    Objects_Database_Delete = 'Objects_Database_Delete',
    Objects_Database_General_Read = 'Objects_Database_General_Read',
    Objects_Database_General_Edit = 'Objects_Database_General_Edit',
    Objects_Database_Address_Read = 'Objects_Database_Address_Read',
    Objects_Database_Address_Edit = 'Objects_Database_Address_Edit',
    Objects_Database_ChangeRequests_Read = 'Objects_Database_ChangeRequests_Read',
    Objects_Database_ChangeRequests_Edit = 'Objects_Database_ChangeRequests_Edit',
    Objects_Database_BMA_Read = 'Objects_Database_BMA_Read',
    Objects_Database_BMA_Edit = 'Objects_Database_BMA_Edit',
    Objects_Database_Hazards_Read = 'Objects_Database_Hazards_Read',
    Objects_Database_Hazards_Edit = 'Objects_Database_Hazards_Edit',
    Objects_Database_Radio_Read = 'Objects_Database_Radio_Read',
    Objects_Database_Radio_Edit = 'Objects_Database_Radio_Edit',
    Objects_Database_Safety_Read = 'Objects_Database_Safety_Read',
    Objects_Database_Safety_Edit = 'Objects_Database_Safety_Edit',
    Objects_Database_Contacts_Read = 'Objects_Database_Contacts_Read',
    Objects_Database_Contacts_Edit = 'Objects_Database_Contacts_Edit',
    Objects_Database_HomeEmergency_Read = 'Objects_Database_HomeEmergency_Read',
    Objects_Database_HomeEmergency_Edit = 'Objects_Database_HomeEmergency_Edit',
    Objects_Database_EmergencyPower_Read = 'Objects_Database_EmergencyPower_Read',
    Objects_Database_EmergencyPower_Edit = 'Objects_Database_EmergencyPower_Edit',
    Objects_Database_LockingSystem_Read = 'Objects_Database_LockingSystem_Read',
    Objects_Database_LockingSystem_Edit = 'Objects_Database_LockingSystem_Edit',
    Objects_Database_RescueServices_Read = 'Objects_Database_RescueServices_Read',
    Objects_Database_RescueServices_Edit = 'Objects_Database_RescueServices_Edit',
    Objects_Database_Events_Read = 'Objects_Database_Events_Read',
    Objects_Database_Events_Edit = 'Objects_Database_Events_Edit',
    Objects_Database_Documents_Read = 'Objects_Database_Documents_Read',
    Objects_Database_Documents_Edit = 'Objects_Database_Documents_Edit',
    Objects_Database_Subobject_Read = 'Objects_Database_Subobject_Read',
    Objects_Database_Subobject_Edit = 'Objects_Database_Subobject_Edit',
    Objects_Database_Markers_Read = 'Objects_Database_Markers_Read',
    Objects_Database_Markers_Edit = 'Objects_Database_Markers_Edit',
    Objects_Database_Map_Read = 'Objects_Database_Map_Read',
    Objects_Database_Buildings_Read = 'Objects_Database_Buildings_Read',
    Objects_Database_Buildings_Edit = 'Objects_Database_Buildings_Edit',
    Objects_Database_Notes_Read = 'Objects_Database_Notes_Read',
    Objects_Database_Notes_Write = 'Objects_Database_Notes_Write',
    Objects_Database_History_Read = 'Objects_Database_History_Read',
    Objects_Database_Export_Examinations = 'Objects_Database_Export_Examinations',
    Objects_Database_TriggerReasons_Export = 'Objects_Database_TriggerReasons_Export',
    Objects_Layers = 'Objects_Layers',
    Objects_Layers_Create = 'Objects_Layers_Create',
    Objects_Layers_Edit = 'Objects_Layers_Edit',
    Objects_Layers_Delete = 'Objects_Layers_Delete',
    Objects_Markers = 'Objects_Markers',
    Objects_Markers_Create = 'Objects_Markers_Create',
    Objects_Markers_Edit = 'Objects_Markers_Edit',
    Objects_Markers_Delete = 'Objects_Markers_Delete',
    Objects_Symbols = 'Objects_Symbols',
    Objects_Symbols_Create = 'Objects_Symbols_Create',
    Objects_Symbols_Edit = 'Objects_Symbols_Edit',
    Objects_Symbols_Delete = 'Objects_Symbols_Delete',
    Objects_Database_DirectionCards_Edit = 'Objects_Database_DirectionCards_Edit',
    Objects_Database_DirectionCards_Read = 'Objects_Database_DirectionCards_Read',
    Objects_Database_DirectionCards_Delete = 'Objects_Database_DirectionCards_Delete',
    Objects_Roadblocks = 'Objects_Roadblocks',
    Objects_Roadblocks_Edit = 'Objects_Roadblocks_Edit',
    Objects_Roadblocks_Export = 'Objects_Roadblocks_Export',

    Objects_ObjectDocument = 'Objects_ObjectDocument',
    Objects_ObjectDocument_Create = 'Objects_ObjectDocument_Create',
    Objects_ObjectDocument_Edit = 'Objects_ObjectDocument_Edit',
    Objects_ObjectDocument_Delete = 'Objects_ObjectDocument_Delete',
    Objects_ObjectDocument_Tag = 'Objects_ObjectDocument_Tag',
    Objects_ObjectDocument_Tag_Create = 'Objects_ObjectDocument_Tag_Create',
    Objects_ObjectDocument_Tag_Edit = 'Objects_ObjectDocument_Tag_Edit',
    Objects_ObjectDocument_Tag_Delete = 'Objects_ObjectDocument_Tag_Delete',

    Objects_Database_Events_Export = 'Objects_Database_Events_Export',
    Objects_Roadblocks_Create = 'Objects_Roadblocks_Create',
    Objects_Roadblocks_Delete = 'Objects_Roadblocks_Delete',
    Objects_Paths = 'Objects_Paths',
    Objects_Database_Export = 'Objects_Database_Export',
    Availability = 'Availability',
    Availability_Overview = 'Availability_Overview',
    Availability_Diagrams = 'Availability_Diagrams',
    Availability_GroupsFunctions = 'Availability_GroupsFunctions',
    Availability_Rules = 'Availability_Rules',
    Availability_Rules_Create = 'Availability_Rules_Create',
    Availability_Rules_Edit = 'Availability_Rules_Edit',
    Availability_Rules_Delete = 'Availability_Rules_Delete',
    Availability_Planning = 'Availability_Planning',
    Availability_Access = 'Availability_Access',
    Availability_Access_CreateKey = 'Availability_Access_CreateKey',
    Availability_Access_EditKey = 'Availability_Access_EditKey',
    Availability_Access_DeleteKey = 'Availability_Access_DeleteKey',
    Availability_Access_OpenOnline = 'Availability_Access_OpenOnline',
    EventPlanning = 'EventPlanning',
    EventPlanning_Calendar = 'EventPlanning_Calendar',
    EventPlanning_Calendar_Create = 'EventPlanning_Calendar_Create',
    EventPlanning_Calendar_Edit = 'EventPlanning_Calendar_Edit',
    EventPlanning_Calendar_Delete = 'EventPlanning_Calendar_Delete',
    EventPlanning_Calendar_Share = 'EventPlanning_Calendar_Share',
    EventPlanning_Appointment = 'EventPlanning_Appointment',
    EventPlanning_Appointment_Create = 'EventPlanning_Appointment_Create',
    EventPlanning_Appointment_Edit = 'EventPlanning_Appointment_Edit',
    EventPlanning_Appointment_Delete = 'EventPlanning_Appointment_Delete',
    EventPlanning_Calendar_Ical_Share = 'EventPlanning_Calendar_Ical_Share',
    Statistics = 'Statistics',
    Statistics_Read = 'Statistics_Read',
    Statistics_Delete = 'Statistics_Delete',
    Settings = 'Settings',
    Settings_ChangePassword = 'Settings_ChangePassword',
    Settings_ChangeAppPassword = 'Settings_ChangeAppPassword',
    Settings_ViewOwnPrivileges = 'Settings_ViewOwnPrivileges',
    Settings_Wasserkarte = 'Settings_Wasserkarte',
    Settings_ObjectsCenter = 'Settings_ObjectsCenter',
    Settings_StationBook = 'Settings_StationBook',
    Settings_Amobile_AMweb = 'Settings_Amobile_AMweb',
    Home_Alarms_UserLocationDisplay = 'Home_Alarms_UserLocationDisplay',
    Settings_UserLocationDisplay = 'Settings_UserLocationDisplay',
    Settings_SmsFlatrate = 'Settings_SmsFlatrate',
    Settings_FireboardPoi = 'Settings_FireboardPoi',
    Settings_ExternalApi = 'Settings_ExternalApi',
    Settings_Privacy = 'Settings_Privacy',
    Settings_GoogleMapsApi = 'Settings_GoogleMapsApi',
    Settings_Leaderboard = 'Settings_Leaderboard',
    Settings_Tracking_Sinks='Settings_Tracking_Sinks',
    Admin = 'Admin',
    Admin_Overview = 'Admin_Overview',
    Admin_Inputs = 'Admin_Inputs',
    Admin_Protocol = 'Admin_Protocol',
    Admin_Users = 'Admin_Users',
    Admin_Users_Create = 'Admin_Users_Create',
    Admin_Users_Edit = 'Admin_Users_Edit',
    Admin_Users_Delete = 'Admin_Users_Delete',
    Admin_Users_Change_Licenses = 'Admin_Users_Change_Licenses',
    Admin_Users_Change_Objects = 'Admin_Users_Change_Objects',
    Admin_Users_Delete_Alarms = 'Admin_Users_Delete_Alarms',
    Admin_Relais = 'Admin_Relais',
    Admin_Logs = 'Admin_Logs',
    Admin_KBA = 'Admin_KBA',
    Admin_SmsUsage = 'Admin_SmsUsage',
    Admin_PersonShare = 'Admin_PersonShare',
    Admin_Revision = 'Admin_Revision',
    Admin_CompactStorage = 'Admin_CompactStorage',
    Admin_LoginAsUser = 'Admin_LoginAsUser',
    Admin_Privacy = 'Admin_Privacy',
    Admin_Vehicles = 'Admin_Vehicles',
    Admin_Vehicles_Tracking = 'Admin_Vehicles_Tracking',
    Admin_ParkingPositions = 'Admin_ParkingPositions',
    Admin_BuildingController = 'Admin_BuildingController',
    Admin_Sms_Eagle = 'Admin_Sms_Eagle',
    Admin_Rest_Protocol = 'Admin_Rest_Protocol',
    Admin_Rest_Protocol_Settings = 'Admin_Rest_Protocol_Settings',
    Admin_Rest_Protocol_Delete = 'Admin_Rest_Protocol_Delete',
    Admin_SSO_Settings = 'Admin_SSO_Settings',
    Admin_Chat = 'Admin_Chat',
    Admin_KBA_Approve = 'Admin_KBA_Approve',
    Blocked_Alarms_List = 'Blocked_Alarms_List',
    Announcements = 'Announcements',
    Announcements_Create = 'Announcements_Create',
    Announcements_Edit = 'Announcements_Edit',
    Announcements_Delete = 'Announcements_Delete',
    Announcements_Sticky = 'Announcements_Sticky',
    AAO = 'AAO',
    AAO_Create = 'AAO_Create',
    AAO_Edit = 'AAO_Edit',
    AAO_Delete = 'AAO_Delete',
    Plugin_Templates = 'Plugin_Templates',
    Plugin_Templates_Delete = 'Plugin_Templates_Delete',
    Plugin_Templates_Create = 'Plugin_Templates_Create',
    Plugin_Templates_Edit = 'Plugin_Templates_Edit',
    User_Roles = 'User_Roles',
    User_Roles_Create = 'User_Roles_Create',
    User_Roles_Edit = 'User_Roles_Edit',
    User_Roles_Delete = 'User_Roles_Delete',
    User_Roles_Assign = 'User_Roles_Assign',
    User = 'User',
    Leaderboard = 'Leaderboard',
    Leaderboard_Overview = 'Leaderboard_Overview',
    Alarm_Queues = 'Alarm_Queues',
    Alarm_Queues_Edit = 'Alarm_Queues_Edit',
    Home_Alarm_Map='Home_Alarm_Map',
    Leaderboard_Badges = 'Leaderboard_Badges',

    Devices = 'Devices',
    Devices_Create = 'Devices_Create',
    Devices_Delete = 'Devices_Delete',
    Devices_Edit = 'Devices_Edit',
    Devices_Change_State = 'Devices_Change_State'
}