import {LocationMapTaskPayload} from './../../../../data/locationmap.data';

'use strict';

import {
  DiaryEntry,
  DiaryPayload,
  EConfidentialState,
  ELagekarteDiaryPriority,
  ELagekarteDiaryType,
  ELocationMapTaskStatus,
  LocationMapTask
} from '../../../../data/locationmap.data';
import {
  EFinishMode,
  EQueueEntryState,
  QueueEntryResponse,
  QueueEntrySelectedEvent,
  QueueEntryVehicle,
  UpdateQueuePriorityRequest,
  UpdateQueueStateRequest
} from '../../../../data/queue.data';
import {EVehicleStatus, StatusEntryResponse, VehicleNameIDResponse} from '../../../../data/vehicles.data';
import * as L from "leaflet";
import RestService from "../../../../services/rest.service";
import MapService from "../../../../services/map.service";
import VehicleService from '../../../../services/vehicle.service';
import {IHttpService, IRootScopeService, IScope, IWindowService} from 'angular';
import PrivilegeService from '../../../../services/privilege.service';
import {RolePrivilege} from '../../../../data/privileges.enum';
import {UserAccount} from '../../../../data/account.data';
import HelperService from '../../../../services/helper.service';

require('./queue.detail.menu.scss');

export default class QueueDetailMenuComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'EA'
    this.scope = {
      queue: '='
    }
    this.template = require('./queue.detail.menu.html');

    this.controller = QueueDetailMenuComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueDetailMenuComponentController {

  private listeners: any[] = [];
  private locationMapErrorListener;
  private account: UserAccount;
  public selectedQueueEntry: QueueEntryResponse;
  public selectedQueueEntryDiaryEntries: DiaryEntry[] = [];
  public selectedQueueEntryTaskEntries: LocationMapTask[] = [];
  public selectedQueueEntryMarker: L.Marker;
  public queueEntryMenuMap: L.Map;
  public newFeedbackMessage: string = '';
  public newTaskMessage: string = '';
  public newFeedbackLoading: boolean;
  public newTaskLoading: boolean;
  public statusColorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
  public statusTextColorMapping: Map<EVehicleStatus, string> = new Map<EVehicleStatus, string>();
  public currentTab: 'TASKS' | 'FEEDBACK' | 'VEHICLES' = 'FEEDBACK';
  public hasCoords: boolean;
  private bcQueueSelected: BroadcastChannel = new BroadcastChannel('queue.QUEUE_ENTRY_SELECTED');
  public loadingVehicle: boolean;
  public searchedVehicle: VehicleNameIDResponse;
  private onlyAssignedVehicles = false;
  public thumbnailToken: string;
  public closingAllowed: boolean;
  public locationMapId: string;
  public locationMapError: boolean;

  constructor(private $scope: IScope,
              private $rootScope: IRootScopeService,
              private restService: RestService,
              public $uibModal: any,
              public mapService: MapService,
              private vehicleService: VehicleService,
              private Notification: any,
              public privilegeService: PrivilegeService,
              private $translate: any,
              private $document,
              private dataService: any, helperService: HelperService, public $window: IWindowService, public $http: IHttpService) {

    this.initListeners();
    this.onlyAssignedVehicles = helperService.getFromStorage('QUEUE.ONLY_ASSIGNED', false);

    if (this.dataService.hasAccount()) {
      this.initQueueEntryMenuMap();

    } else {
      let subscription = this.$rootScope.$on('new.account', (event, account) => {
        this.account = account;
        this.queueEntryMenuMap?.remove();
        this.queueEntryMenuMap = undefined;
        this.$document.ready(() => {
          this.initQueueEntryMenuMap();
        });
        subscription();
      });
    }
  }

  getThumbnailToken() {
    if (!this.locationMapId) {
      return;
    }
    this.restService.getThumbnailToken(this.locationMapId, this.selectedQueueEntry.id).then(token => {
      this.thumbnailToken = token.replace('Bearer ', '');
    }).catch(error => {
      if (error.status !== 412) { // ignore 412 because 412 says lagekarte is not saved in FE2 DB
        this.Notification.error({
          message: error
        });
      }
    }).finally(() => this.$scope.$applyAsync());
  }

  initListeners() {

    this.listeners.push(this.$rootScope.$on('status.change', (event, incomingStatus: StatusEntryResponse) => {
      this.selectedQueueEntry?.alarmData.vehicles?.forEach(element => {
        if (element.vehicleId === incomingStatus.vehicleId) {
          element.status = incomingStatus.status;
          element.statusColor = incomingStatus.color;
          element.statusTextColor = incomingStatus.textColor;
          element.statusTime = incomingStatus.timestamp;
          this.$scope.$applyAsync();
        }
      });
    }));

    this.listeners.push(this.$rootScope.$on('queue.only_assigned', (event, value) => {
      this.onlyAssignedVehicles = value;
    }));

    this.listeners.push(this.$rootScope.$on('new.alarm', (event, value) => {
      if (value?.externalId === this.selectedQueueEntry?.externalId) {
        //get newest alarmdata for open entry
        this.restService.getQueueEntryWithNewestAlarmData(this.selectedQueueEntry.id).then(entry => {
          this.selectedQueueEntry = entry;
        }).finally(() => this.$scope.$applyAsync());
      }
    }));

    this.listeners.push(this.$rootScope.$on('LAGEKARTE_UPDATED', (event, externalId: string) => {
      if (this.selectedQueueEntry?.externalId === externalId) {
        this.loadQueueFeedbackEntries();
      }
    }));

    this.listeners.push(this.$rootScope.$on('queue.QUEUE_ENTRY_UPDATED', (event, data: QueueEntryResponse) => {
      if (data !== undefined) {
        const id = this.selectedQueueEntry?.id;
        if (id === data?.id)
          this.handleQueueEntryReceived(data);
      }
    }));


    this.bcQueueSelected.onmessage = (event) => {
      const bcEntry = event?.data?.bcEntry as QueueEntrySelectedEvent;
      this.handleQueueEntryReceived(bcEntry?.entry);
      if (bcEntry?.entry) {
        if (bcEntry.hasNewAdditionalRequest || bcEntry.hasNewImportantMessage) {
          // delay 500ms to prevent mixing of websocket events
          setTimeout(() => this.restService.updateQueueEntryUnreadMessages(bcEntry.entry.id), 500);
        }
      }
    }

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  initQueueEntryMenuMap() {
    if (this.queueEntryMenuMap) {
      return;
    }
    L.Icon.Default.imagePath = '/img/static';
    this.queueEntryMenuMap = L.map('queue-detail-map', {
      attributionControl: false,
      zoomControl: false
    });
    let layers = this.mapService.getBaseLayers();
    let selectedLayer = this.mapService.getSelectedLayer();
    if (selectedLayer == undefined || layers[selectedLayer] == undefined) {
      selectedLayer = "OpenStreetMap";
    }
    layers[selectedLayer].addTo(this.queueEntryMenuMap);
  }

  closeMenu($event) {
    $event.stopPropagation();
    this.bcQueueSelected.postMessage(undefined);
  }


  handleQueueEntryReceived(data: QueueEntryResponse) {

    if (data) {

      this.locationMapId = data.alarmData?.locationMapId;
      this.selectedQueueEntry = data;

      if (this.locationMapErrorListener) {
        this.locationMapErrorListener();
      }
      this.locationMapError = false;
      this.locationMapErrorListener = this.$rootScope.$on('queue.' + this.selectedQueueEntry.id + '.locationMapError', (event, error: any) => {
        if (error.status === 401) {
          this.locationMapError = true;
        } else {
          this.locationMapError = false;
        }
      });
      this.loadQueueFeedbackEntries();

      setTimeout(() => {
        this.getThumbnailToken();
        this.updateMapForSelectedQueueEntry();
      }, 100);
    } else {
      this.reset();
    }
    this.$scope.$applyAsync();
  }

  updateMapForSelectedQueueEntry() {

    if (this.selectedQueueEntryMarker) {
      this.queueEntryMenuMap?.removeLayer(this.selectedQueueEntryMarker);
      this.selectedQueueEntryMarker = undefined;
    }

    if (!this.queueEntryMenuMap) {
      return;
    }

    if (this.selectedQueueEntry) {
      if (this.selectedQueueEntry.alarmData.lat && this.selectedQueueEntry.alarmData.lng) {
        this.hasCoords = true;
        const latLng = L.latLng(this.selectedQueueEntry.alarmData.lat, this.selectedQueueEntry.alarmData.lng);
        this.selectedQueueEntryMarker = L.marker(latLng, {draggable: false});
        this.selectedQueueEntryMarker.addTo(this.queueEntryMenuMap);
        this.queueEntryMenuMap.setView(latLng, 17);
      } else {
        this.hasCoords = false;
        this.queueEntryMenuMap.setView(L.latLng(0,0), 17)
      }
    }
  }

  async loadQueueFeedbackEntries() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Diary_Read) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    // load feedbacks from FE2 for queue entry
    if (this.locationMapId) {
      await this.restService.getDiaryEntriesForLocationMap(this.selectedQueueEntry?.id, this.locationMapId).then(entries => {
        if (entries) {
          this.selectedQueueEntryDiaryEntries = entries;
        } else {
          this.selectedQueueEntryDiaryEntries = [];
        }
        this.newFeedbackLoading = false;
        this.$scope.$applyAsync();
      }).catch(error => {
        if (error.status === 412) {
          this.locationMapId = undefined;
        }
      });
      if (this.locationMapId) {
        await this.restService.getTaskEntriesForLocationMap(this.selectedQueueEntry?.id, this.locationMapId).then(entries => {
          if (entries) {
            this.selectedQueueEntryTaskEntries = entries;
            var hasOpenTasks = this.selectedQueueEntryTaskEntries.some(entry => entry.payload.status === ELocationMapTaskStatus.NEW || entry.payload.status === ELocationMapTaskStatus.IN_PROGRESS);
            this.closingAllowed = !hasOpenTasks;
          } else {
            this.selectedQueueEntryTaskEntries = [];
            this.closingAllowed = true;
          }
          this.newTaskLoading = false;
          this.$scope.$applyAsync();
        }).catch(error => {
          if (error.status === 412) {
            this.locationMapId = undefined;
          }
        });
      } else {
        this.selectedQueueEntryDiaryEntries = [];
        this.selectedQueueEntryTaskEntries = [];
        this.newFeedbackLoading = false;
        this.newTaskLoading = false;
        this.closingAllowed = true;
        this.$scope.$applyAsync();
      }
    } else {
      this.selectedQueueEntryDiaryEntries = [];
      this.selectedQueueEntryTaskEntries = [];
      this.newFeedbackLoading = false;
      this.newTaskLoading = false;
      this.closingAllowed = true;
      this.$scope.$applyAsync();
    }
  }

  loadStatusColorMapping() {
    if (!(this.privilegeService.has(RolePrivilege.Station_Vehicles) || this.privilegeService.has(RolePrivilege.Station_Sirens))) {
      return;
    }
    this.vehicleService.getStatusColorMapping()
      .then(statusColorMapping => this.statusColorMapping = statusColorMapping)
      .then(() => this.vehicleService.getStatusTextColorMapping())
      .then(statusTextColorMapping => this.statusTextColorMapping = statusTextColorMapping)
      .finally(() => {
        this.$scope.$applyAsync();
      });
  }

  saveNewFeedback() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Diary_Write) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    this.newFeedbackLoading = true;
    if (this.locationMapId && this.newFeedbackMessage?.length > 0) {
      var payload = {
        message: this.newFeedbackMessage,
        confidentiality: EConfidentialState.NONE,
        priority: ELagekarteDiaryPriority.DEFAULT,
        type: ELagekarteDiaryType.MESSAGE
      } as DiaryPayload;
      this.restService.createDairyEntryForLagekarte(this.selectedQueueEntry.id, this.locationMapId, payload).then(() => {
        this.newFeedbackMessage = '';
      }).catch(error => {
        this.$rootScope.$emit('queue.' + this.selectedQueueEntry.id + '.locationMapError', error);

        if (error.status !== 401) {
          this.$translate(['COMMON.ERROR_TITLE']).then((translations) => {
            this.Notification.error({
              message: error,
              title: translations['COMMON.ERROR_TITLE']
            });
          });
        }

      }).finally(() => {
        setTimeout(() => document.getElementById('newFeedbackInput').focus(), 200);
      });
    }
  }

  saveNewTask() {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Tasks_Write) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    this.newTaskLoading = true;
    if (this.locationMapId && this.newTaskMessage?.length > 0) {
      var payload = {
        message: this.newTaskMessage
      } as LocationMapTaskPayload;
      this.restService.createTaskEntryForLagekarte(undefined, this.locationMapId, this.selectedQueueEntry.id, payload).then(() => {
        this.newTaskMessage = '';
      }).catch(error => {
        this.$rootScope.$emit('queue.' + this.selectedQueueEntry.id + '.locationMapError', error);

        if (error.status !== 401) {
          this.$translate(['COMMON.ERROR_TITLE']).then((translations) => {
            this.Notification.error({
              message: error,
              title: translations['COMMON.ERROR_TITLE']
            });
          });
        }
      }).finally(() => {
        setTimeout(() => document.getElementById('newTaskInput').focus(), 200);
      });
    }
  }

  selectTab(newTab: 'TASKS' | 'FEEDBACK' | 'VEHICLES') {
    this.currentTab = newTab;
    this.$scope.$applyAsync();
  }

  done(mode: EFinishMode): void {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Edit) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }

    if (!this.closingAllowed) {
      this.$translate(['QUEUES.DONE_NOT_ALLOWED', 'QUEUES.DONE_NOT_ALLOWED_TITLE']).then(texts => {
        this.Notification.warning({
          message: texts['QUEUES.DONE_NOT_ALLOWED'],
          title: texts['QUEUES.DONE_NOT_ALLOWED_TITLE'],
        });
      })

      return;
    }
    this.$uibModal.open({
      template: require('../../../modals/queues/confirm.queue.close.modal/confirm.queue.close.modal.html'),
      controller: 'ConfirmQueueCloseModalController',
      controllerAs: 'ctrl',
      size: 'sm',
      backdrop: 'static',
      resolve: {
        okFunction: () => {
          return (reportMessage) => {
            this.restService.updateQueueEntryState(this.selectedQueueEntry.id, {
              state: EQueueEntryState.DONE,
              finishMode: mode,
              queueId: this.selectedQueueEntry.queueId,
              reportMessage
            } as UpdateQueueStateRequest).then(entry => {
              this.selectedQueueEntry = entry;
              this.$scope.$applyAsync();
            });
          };
        }
      }
    });
  }

  exportAsPdf() {
    let url = `${this.restService.getBaseUrl()}/queues/mission/${this.selectedQueueEntry.externalId}/export?Authorization=${this.$http.defaults.headers.common.Authorization}`;
    this.$window.open(url, '_blank');
  }

  reset() {
    // delay reset by 400ms to allow a smooth transition with menu closing
    setTimeout(() => {
      this.selectedQueueEntryDiaryEntries = [];
      this.locationMapId = undefined;
      this.thumbnailToken = undefined;
      this.selectedQueueEntry = undefined;
      this.locationMapError = false;
      if (this.selectedQueueEntryMarker) {
        this.queueEntryMenuMap?.removeLayer(this.selectedQueueEntryMarker);
        this.selectedQueueEntryMarker = undefined;
      }
      this.currentTab = 'FEEDBACK';
      this.newFeedbackMessage = '';
      this.$scope.$applyAsync();
    }, 400);
  }

  priorityUp($event) {
    $event.stopPropagation();

    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Edit) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }

    if (this.selectedQueueEntry.state === EQueueEntryState.DONE) return;

    if (this.selectedQueueEntry.priority === 4) {
      this.selectedQueueEntry.priority = 1;
    } else {
      this.selectedQueueEntry.priority++;
    }
    this.changePriority(this.selectedQueueEntry.priority);
  }

  changePriority(newPriority: number): void {
    this.restService.updateQueueEntryPriority(this.selectedQueueEntry.id, {
      priority: newPriority
    } as UpdateQueuePriorityRequest).then(result => {
      this.selectedQueueEntry.priority = result.priority;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  assignVehicle(item: VehicleNameIDResponse) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Edit) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    this.restService.assignVehicleToQueue(this.selectedQueueEntry.id, item.id).then(response => {
      this.selectedQueueEntry = response;
      this.triggerUpdates();
    }).finally(() => {
      this.searchedVehicle = undefined;
      this.$scope.$applyAsync();
    });
  }

  private triggerUpdates() {
    this.$rootScope.$emit(`queue.entry.${this.selectedQueueEntry.id}.update`, this.selectedQueueEntry);
    if (this.selectedQueueEntry.similarEntries) {
      this.selectedQueueEntry.similarEntries.forEach(entry => {
        if (entry) {
          this.$rootScope.$emit(`queue.${entry.queueId}.update`);
        }
      })
    }
  }

  removeVehicle(item: QueueEntryVehicle) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Edit) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    this.restService.removeVehicleFromQueue(this.selectedQueueEntry.id, item.vehicleId).then(response => {
      this.selectedQueueEntry = response;
      this.triggerUpdates()
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  searchVehicle(val) {
    if (!this.privilegeService.has(RolePrivilege.Alarm_Queues_Edit) && this.selectedQueueEntry.state !== EQueueEntryState.DONE) {
      return;
    }
    return new Promise<any>((resolve) => {
      if (!this.account) {
        this.account = this.dataService.getAccount();
      }
      //Filter out already selected vehicles
      this.restService.searchForVehiclesAccessibleForUser(val, this.account.id, 10, this.onlyAssignedVehicles).then((searchResult: any[]) => {
        let filtered = [];
        if (searchResult) {
          filtered = searchResult.filter(element => {
            return this.selectedQueueEntry.alarmData.vehicles.find(entry => entry.vehicleId === element.id) === undefined;
          });
        }
        resolve(filtered);
      }).catch(error => console.error(error));
    });
  }
}
